import { useMemo } from "react";
import { ItemType } from "sprout-graphql";
import { VerticalDistributeOutlined, TopicOutlined, TaskAltOutlined, InboxOutlined } from "sprout-ui-icons";
import { getItemUrl, isNotNull } from "sprout-lib";
import { useJumpHistories } from "@sprout/context/dist/jumpHistories";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import useSpecialTopic from "@sprout/hooks/dist/useSpecialTopic";
import processJumpHistories from "./processJumpHistories";
import useJumpToTopics from "./useJumpToTopics";
import useJumpToMoreGroups from "./useJumpToMoreGroups";
const APP = "APP";
function processTopics({ topics , currentUserId  }) {
    return topics.filter((topic)=>{
        return !(topic.deletedAt || topic.archivedAt);
    }).map((topic)=>{
        const { rootTopicOrSelf , title , id  } = topic;
        const { owner  } = rootTopicOrSelf || {};
        const isRootTopic = (rootTopicOrSelf === null || rootTopicOrSelf === void 0 ? void 0 : rootTopicOrSelf.id) === id;
        // deleted user
        if (!owner) return null;
        const url = getItemUrl(topic, owner);
        return {
            url,
            label: title || "No title",
            icon: isRootTopic ? TopicOutlined : VerticalDistributeOutlined,
            context: owner.id === currentUserId ? undefined : owner.username,
            type: ItemType.Topic
        };
    })// remove null items due to deleted user
    .filter(isNotNull);
}
export default function useJumpToGroups() {
    const currentUser = useCurrentUser();
    const { jumpHistories  } = useJumpHistories();
    const { topics , workspaces  } = useJumpToTopics();
    const moreItems = useJumpToMoreGroups();
    const { myTasksURL , readLaterURL  } = useSpecialTopic();
    return useMemo(()=>{
        return [
            {
                group: "RECENT",
                items: processJumpHistories(jumpHistories, currentUser)
            },
            {
                group: "Special Trees",
                items: [
                    {
                        label: "To-dos",
                        icon: TaskAltOutlined,
                        url: myTasksURL,
                        type: APP
                    },
                    {
                        label: "Read Later",
                        icon: InboxOutlined,
                        url: readLaterURL,
                        type: APP
                    }, 
                ]
            },
            {
                group: "Personal Trees",
                items: processTopics({
                    topics,
                    currentUserId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id
                })
            },
            ...workspaces.map(({ workspace , topics: workspaceTopics  })=>{
                return {
                    group: workspace.username,
                    items: workspaceTopics.map((topic)=>{
                        const { title  } = topic;
                        const url = getItemUrl(topic, workspace);
                        return {
                            url,
                            label: title || "No title",
                            icon: TopicOutlined,
                            type: ItemType.Topic
                        };
                    })
                };
            }),
            ...moreItems
        ].filter((group)=>group.items.length !== 0);
    }, [
        jumpHistories,
        currentUser,
        myTasksURL,
        readLaterURL,
        topics,
        workspaces,
        moreItems, 
    ]);
};
