import styled from "styled-components";
import { themeGet, media } from "sprout-ui-theme";
const JumpToInput = styled.input`
  height: 40px;
  padding: 0 8px;

  margin-bottom: 24px;

  appearance: none;
  border: 0;
  outline: 0;

  border: 1px solid ${themeGet("colors.black.12")};

  width: 100%;

  ::placeholder {
    color: ${themeGet("colors.black.60")};
  }

  border-radius: 30px;

  ${media.target("touch")} {
    display: none;
  }

  & + div .JumpToNoMatchGroup:first-child {
    margin-top: 16px;
  }
`;
export default JumpToInput;
