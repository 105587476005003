import { useMemo } from "react";
import { ItemMemberLevel, useJumpToQuery } from "sprout-graphql";
import naturalCompare from "natural-compare";
import update from "immutability-helper";
import { isNotNull, isPresent } from "sprout-lib";
import sortStarredTopics from "@sprout/client-lib/dist/sortStarredTopics";
export default function useJumpToTopics() {
    const { data  } = useJumpToQuery({
        fetchPolicy: "cache-and-network"
    });
    const currentUser = data === null || data === void 0 ? void 0 : data.currentUser;
    const workspaces = useMemo(()=>{
        if (!currentUser) return [];
        return currentUser.workspaces.nodes.map(({ workspace  })=>{
            const workspaceTopics = ((workspace === null || workspace === void 0 ? void 0 : workspace.topics.nodes) || []).filter((topic)=>{
                const { memberLevel =null  } = topic.rootTopicOrSelf || {};
                return memberLevel ? ![
                    ItemMemberLevel.Invited,
                    ItemMemberLevel.Pending
                ].includes(memberLevel) : false;
            }).sort((topicA, topicB)=>{
                const titleA = topicA.title || "";
                const titleB = topicB.title || "";
                return naturalCompare(titleA.toLowerCase(), titleB.toLowerCase());
            });
            if (workspace) return {
                workspace: update(workspace, {
                    $unset: [
                        "topics"
                    ]
                }),
                topics: workspaceTopics
            };
            return null;
        }).filter(isNotNull);
    }, [
        currentUser
    ]);
    const workspacesTopicIds = workspaces.reduce((acc, currentValue)=>{
        return [
            ...acc,
            ...currentValue.topics.map(({ id  })=>id)
        ];
    }, []);
    const topics = useMemo(()=>{
        if (!currentUser) return [];
        return currentUser.topics.nodes.map(({ topic  })=>topic).filter(isPresent).filter((topic)=>!workspacesTopicIds.includes(topic.id)).sort((topicA, topicB)=>{
            const titleA = topicA.title || "";
            const titleB = topicB.title || "";
            return naturalCompare(titleA.toLowerCase(), titleB.toLowerCase());
        });
    }, [
        currentUser,
        workspacesTopicIds
    ]);
    const starredTopicIds = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.starredTopicIds) || [];
    const starredTopics = sortStarredTopics((currentUser === null || currentUser === void 0 ? void 0 : currentUser.starredTopics.nodes) || [], starredTopicIds);
    return {
        topics,
        starredTopics,
        workspaces
    };
};
