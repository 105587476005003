import styled from "styled-components";
import { themeGet, media } from "sprout-ui-theme";
import { ScrollContainer } from "sprout-ui-dom/dist/ScrollContainer";
export const JumpToWrapper = styled("div")`
  position: fixed;
  max-width: 500px;
  top: 56px;
  bottom: 0;
  left: 50%;
  width: 100vw;
  z-index: 101;

  background: ${themeGet("colors.blue.500")};
  box-shadow: 0 1px 4px ${themeGet("colors.black.16")};

  border-radius: 30px 30px 0 0;
  padding: 16px;

  opacity: 0;
  transform-origin: left center;
  transform: scale(0.98) translate3d(-50%, 0, 0);
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1),
    opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &[class*="enter-"] {
    opacity: 1;
    transform: scale(1) translate3d(-50%, 0, 0);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  }

  ${media.up("medium", "pointer")} {
    bottom: auto;
    width: calc(100vw - 32px);
    border-radius: 30px;
    padding: 16px 24px 16px;
  }
`;
export const JumpToScrollContainer = styled(ScrollContainer)`
  max-height: 100%;

  ${media.up("medium", "pointer")} {
    max-height: calc(100vh - 120px - 200px);
  }
`;
