import { ItemType, UserType } from "sprout-graphql";
import { getReadableDescription, getItemUrl, isNotNull } from "sprout-lib";
import { EditOutlined, TopicOutlined, VerticalDistributeOutlined, ArchiveOutlined, Timeline, OrderBoolAscendingVariant, CheckCircleOutlineOutlined, TaskAltOutlined, InboxOutlined } from "sprout-ui-icons";
import getFullName from "@sprout/client-lib/dist/getFullName";
import jumpToData from "@sprout/context/dist/jumpToData";
export default function processJumpHistories(jumpHistories, currentUser) {
    return jumpHistories.map((jumpHistory)=>{
        const { item , user  } = jumpHistory;
        const { search  } = jumpHistory;
        if (search.type === "APP") {
            const url = typeof search.data === "object" && search.data && !Array.isArray(search.data) ? search.data.pathname : undefined;
            if (typeof url !== "string" || !(url in jumpToData)) return null;
            const { label , icon  } = jumpToData[url];
            return {
                url,
                icon,
                label,
                type: "APP"
            };
        }
        if (search.type === ItemType.Post && item) {
            const { description , linkTitle , parentTopic , linkImage  } = item;
            return {
                url: getItemUrl(item),
                label: description ? getReadableDescription(description) : linkTitle || "No description",
                image: linkImage || undefined,
                context: (parentTopic === null || parentTopic === void 0 ? void 0 : parentTopic.title) || undefined,
                type: ItemType.Post,
                icon: EditOutlined
            };
        }
        if (search.type === ItemType.Checklist && item) {
            const { description , linkTitle  } = item;
            return {
                url: getItemUrl(item),
                label: description ? getReadableDescription(description) : linkTitle || "No description",
                type: ItemType.Checklist,
                icon: OrderBoolAscendingVariant
            };
        }
        if (search.type === ItemType.ChecklistItem && item) {
            const { description , linkTitle  } = item;
            return {
                url: getItemUrl(item),
                label: description ? getReadableDescription(description) : linkTitle || "No description",
                type: ItemType.ChecklistItem,
                icon: CheckCircleOutlineOutlined
            };
        }
        if (search.type === ItemType.Topic && item) {
            const { rootTopicOrSelf , title , id  } = item;
            const { owner , special  } = rootTopicOrSelf || {};
            const isRootTopic = (rootTopicOrSelf === null || rootTopicOrSelf === void 0 ? void 0 : rootTopicOrSelf.id) === id;
            // deleted user
            if (!owner) return null;
            const url = getItemUrl(item, owner);
            if (typeof search.data !== "object" || search.data == null || Array.isArray(search.data)) return null;
            const { view  } = search.data;
            if (view === "HOME") return {
                url,
                label: title || "No title",
                context: owner.id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) ? undefined : owner.username,
                icon: special ? special === "MY_TASKS" ? TaskAltOutlined : InboxOutlined : isRootTopic ? TopicOutlined : VerticalDistributeOutlined,
                type: ItemType.Topic
            };
            if (view === "ARCHIVE") return {
                url: `${url}/archive`,
                label: `Archived in ${title || ""}`,
                icon: ArchiveOutlined,
                type: ItemType.Topic
            };
            if (view === "ACTIVITY") return {
                url: `${url}/activity`,
                label: `Activity in ${title || ""}`,
                icon: Timeline,
                type: ItemType.Topic
            };
        }
        if ((search.type === UserType.User || search.type === UserType.Team) && user) return {
            user,
            url: `/${user.username}`,
            label: getFullName(user),
            context: user.username,
            type: UserType.User
        };
        return null;
    })// remove null items due to deleted user
    .filter(isNotNull);
};
